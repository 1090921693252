import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Project from '../templates/Project';
import Content from '../components/Content';
import WorkDone from '../components/WorkDone'
import projects from '../project_data'
import mastImage from '../images/projects/utah-jazz/mast.jpg'
import SkillCreator from '../components/SkillCreator/SkillCreator';
import ProjectLinks from '../components/ProjectLinks/projectLinks';
import FeatureCreator from '../components/FeatureCreator/FeatureCreator';

const UtahJazz = ({ data, path }) => {

    return (
        <Project
            title="Utah Jazz"
            image={mastImage}
            pathname={path}
        >
            <Content header="About">
                <p>
                Utah Jazz is a multiple-page responsive website 
                that showcases the home and detail view for Eide 
                Bailly Suite Level premium seating. This project 
                was part of Round 2 of their interview process. It 
                was designed on Adobe XD and developed using React, 
                react-router, and SASS.
                </p>
                <div className="project-page-work">
                    <h2 className="project-page-work--title">Accomplishments</h2>
                        <WorkDone  projectData={projects.get('uj')} />
                </div>
                <div className="project-page-tech">
                    <SkillCreator projectData={projects.get('uj')} />
                </div>
                <div className="project-page-btns">
                    <ProjectLinks projectData={projects.get('uj')} />
                </div>
                <FeatureCreator projectData={projects.get('uj')} />
             </Content>
        </Project>
    )
}


UtahJazz.propTypes = {
    // data: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};
  

export default UtahJazz